<template>
  <BasePage :isLoaded="isLoaded">
    <template v-slot:content>
      <div ref="animation" v-resize.initial="onResize" class="animation -fullFixed -below"></div>
      <div ref="sectionContainer" class="sections">
        <section
          v-for="(section, i) in sections"
          :key="i"
          ref="sections"
          class="-fullFixed -above"
          :class="{[`section-${section.type}`]: !!section.type, [`section-${i}`]: true, [section.class]: !!section.class}"
        >
          <div class="container">
            <v-img
              v-if="section.image"
              class="section-image"
              width="100%"
              :src="section.image.id"
              :aspect-ratio="section.image.width / section.image.height"
              contain
              transition="fade-transition"
            />
            <div class="section-content">
              <h2 v-if="section.text" v-html="section.text"></h2>
              <component
                v-if="section.svg"
                :is="section.svg"
                class="svg"
              />
            </div>
            <ButtonScrollTo
              v-if="i !== sections.length - 1"
              label="Scroll"
              :smallOnMobile="false"
              :target="0.01 + (i+1) * ($root.platform.mobile ? 1.2 : 0.7)"
              class="invite-to-scroll"
            />
          </div>
        </section>
      </div>

    </template>
  </BasePage>
</template>

<script>
import lottie from 'lottie-web'
import Splitting from 'splitting'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
import BasePage from '@/organisms/BasePage'
import ButtonScrollTo from '@/atoms/ButtonScrollTo.vue'
import image from '@/assets/img/weareregi.jpg'
import signature from '@/assets/img/signature.svg'
import GetPageContent from '@/graphql/GetWeAreRegi.gql'
import { optimizedImageURL } from '@/services/OptimizedImage'

const sections = [
  // { type: 'big', text: Splitting.html({ content: 'We are<br/>Regi', by: 'chars' }) },
  { textAnim: 'word', text: Splitting.html({ content: 'We are fresh, inspired, open minded,<br/> we are innovative, experienced, reliable<br/>we work together with a passion ', by: 'chars' }) },
  { text: Splitting.html({ content: 'our language is Pink and Blue', by: 'chars' }) },
  { textAnim: 'word', text: Splitting.html({ content: 'Pink is the color of our passion and love for what we do, of our dynamic and warm environment', by: 'chars' }) },
  { textAnim: 'word', text: Splitting.html({ content: 'Blue stands for our solid scientific and technical knowhow, our top-quality standard, our certified structures and long established reliability.', by: 'chars' }) },
  // { class: 'text-center', text: Splitting.html({ content: '2 colors', by: 'chars' }) },
  // { text: Splitting.html({ content: '2 sides of the same creative spark ', by: 'chars' }) },
  // { type: 'big2', text: Splitting.html({ content: 'Together<br/>we are<br/>Regi', by: 'chars' }) },
  { class: 'text-center', text: Splitting.html({ content: '2 colors.<br/>2 sides of the same creative spark.<br/>Together we are Regi', by: 'chars' }) },
  {
    type: 'small',
    text: Splitting.html({ content: '“I founded Regi in 1994, strong of my passion and understanding of the cosmetic industry.<br/>I wanted to build a company that clients and employees could relate to and rely on. A company with a vibrant, pulsating soul, a place to nurture the idea of co-creation, <br/>A place to work together, to move forward together, to grow together.<br/>Our clients still love this!"', by: 'chars' }),
    image: { id: image, width: 1200, height: 1800 },
    svg: signature
  }
  // { textAnim: 'word', text: Splitting.html({ content: 'I wanted to build a company that clients and employees could relate to and rely on.', by: 'chars' }) },
  // { textAnim: 'word', text: Splitting.html({ content: 'A company with a vibrant, pulsating soul, a place to nurture the idea of co-creation', by: 'chars' }) },
  // { textAnim: 'word', text: Splitting.html({ content: 'A place to work together, to move forward together, to grow together.', by: 'chars' }) },
  // { type: 'big', text: Splitting.html({ content: 'Our clients loved that!', by: 'chars' }) },
  // { textAnim: 'word', type: 'small', text: Splitting.html({ content: 'Regi has since become a leading make-up and skin-care provider, growing alongside the team members and clients who shared the same vision.', by: 'chars' }) },
  // { type: 'svg', svg: signature }
]

export default {
  name: 'WeAreRegiPage',
  apollo: {
    PageContent: {
      query: GetPageContent,
      variables: {
        slug: 'globals'
      },
      async result () {
        this.$nextTick(() => {
          this.initAnimations()
        })
        this.isLoaded = true
      }
    }
  },
  components: {
    BasePage,
    ButtonScrollTo
  },
  data () {
    return {
      sections,
      isLoaded: false
    }
  },
  beforeDestroy () {
    this.timelines.forEach(tl => tl && tl.kill())
  },
  methods: {
    createBodymovin () {
      lottie.setQuality('low')
      const isSimplified = this.$root.platform.mobile || this.$root.platform.safari
      this.bodymovin = lottie.loadAnimation({
        container: this.$refs.animation,
        renderer: isSimplified ? 'canvas' : 'svg',
        loop: false,
        autoplay: false,
        rendererSettings: {
          hideOnTransparent: true,
          progressiveLoad: true,
          preserveAspectRatio: 'xMidYMid slice'
        },
        path: isSimplified
          ? '/media/' + this.PageContent.weAreRegiAnimationMobile.id
          : '/media/' + this.PageContent.weAreRegiAnimationDesktop.id
      })
      this.tlbodyAnimation = gsap.timeline({
        scrollTrigger: {
          trigger: document.body,
          start: 'top top+=1px',
          endTrigger: this.$refs.sectionContainer,
          end: 'bottom bottom',
          scrub: 1
        },
        onUpdate: this.updateBodyMovin
      })
      this.bodyProgress = { progress: 0 }
      this.tlbodyAnimation.fromTo(this.bodyProgress, { progress: 0 }, { progress: 1, ease: 'none' })
    },
    createSectionAnimation () {
      for (let i = 0; i < sections.length; i++) {
        const tl = gsap.timeline({ paused: true })
        tl.fromTo(`.section-${i}`, { autoAlpha: 0 }, { autoAlpha: 1 })
        if (sections[i].text) {
          if (sections[i].textAnim === 'word') {
            const words = gsap.utils.toArray(`.section-${i} .word`)
            words.forEach((word, i) => {
              const chars = word.querySelectorAll('.char')
              tl.fromTo(chars, { yPercent: 200 }, { duration: 0.2, yPercent: 0 }, 0.05 * i)
            })
          } else {
            tl.fromTo(`.section-${i} .char`, { yPercent: 200 }, { duration: 0.2, stagger: 0.01, yPercent: 0 }, 0)
          }
        }
        if (sections[i].svg) {
          tl.fromTo(`.section-${i} .svg path`, { opacity: 0 }, { duration: 0.3, opacity: 1 })
        }
        this.timelines.push(tl)
      }

      this.timelines.push(this.tlbodyAnimation)

      this.tlbodySection = gsap.timeline({
        scrollTrigger: {
          trigger: document.body,
          start: 'top top+=1px',
          endTrigger: this.$refs.sectionContainer,
          end: 'bottom bottom',
          scrub: true
        },
        onUpdate: this.updateSections
      })
      this.sectionProgress = { progress: 0 }
      this.tlbodySection.fromTo(this.sectionProgress, { progress: 0 }, { progress: 1, ease: 'none' })
      this.timelines.push(this.tlbodySection)
      this.updateSections()
    },
    initAnimations () {
      this.timelines = []
      this.createBodymovin()
      this.createSectionAnimation()
    },
    enterSection () {
      this.lastPlayedTl = this.currentIndex + 0
      this.timelines[this.currentIndex].timeScale(1)
      this.timelines[this.currentIndex].play(0)
      this.waitingEnter = false
    },
    updateBodyMovin () {
      // const currentIndex = ~~(this.bodyProgress.progress / (1 / (sections.length)))
      // if (this.prev !== currentIndex) {
      //   console.log('section', currentIndex, ',frame', ~~(this.bodymovin.totalFrames * this.bodyProgress.progress))
      // } else {
      //   console.log('frame', ~~(this.bodymovin.totalFrames * this.bodyProgress.progress))
      // }
      // this.prev = currentIndex
      this.bodymovin.goToAndStop(~~((this.bodymovin.totalFrames - 1) * this.bodyProgress.progress), true)
    },
    updateSections () {
      const currentIndex = ~~(this.sectionProgress.progress / (1 / (sections.length)))
      if (currentIndex !== this.currentIndex && currentIndex < sections.length) {
        this.currentIndex = currentIndex
        if (this.lastPlayedTl === undefined) {
          this.enterSection()
        } else {
          if (!this.waitingEnter) {
            this.waitingEnter = true
            if (this.timelines[this.lastPlayedTl].progress() === 0) {
              this.timelines[this.lastPlayedTl].pause()
              this.timelines[this.lastPlayedTl].eventCallback('onReverseComplete', null)
              this.enterSection()
            } else {
              this.timelines[this.lastPlayedTl].eventCallback('onReverseComplete', this.enterSection)
              this.timelines[this.lastPlayedTl].timeScale(2)
              this.timelines[this.lastPlayedTl].reverse()
            }
          }
        }
      }
    },
    onResize () {
      const factor = this.$root.platform.mobile ? 1.2 : 0.7
      this.$refs.sectionContainer.style.height = `${this.sections.length * factor * window.innerHeight}px`
      this.$refs.sections.forEach(el => (el.style.height = `${window.innerHeight}px`))
      if (this.$refs.animation) {
        this.$refs.animation.style.height = `${Math.max(window.innerHeight || 0, window.outerHeight || 0)}px`
      }
      ScrollTrigger.refresh(true)
      lottie.resize()
    }
  },
  metaInfo: function () {
    if (this.PageContent) {
      let meta = [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.PageContent.weAreRegiMetaTitle },
        { vmid: 'description', name: 'description', content: this.PageContent.weAreRegiMetaDescription },
        { vmid: 'ogdescription', property: 'og:description', content: this.PageContent.weAreRegiMetaDescription }
      ]

      if (this.PageContent.weAreRegiMetaImage) {
        meta = meta.concat([
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + optimizedImageURL(this.PageContent.weAreRegiMetaImage, { width: 1200, height: 625, format: 'jpg', fit: 'crop' }) },
          { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
          { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
          { vmid: 'ogimagewidth', property: 'og:image:height', content: '675' }
        ])
      }

      return {
        title: this.PageContent.weAreRegiMetaTitle,
        meta
      }
    }
  }
}
</script>

<style scoped lang="scss">
.animation {
  display: block;
  transform: translateZ(0);
  pointer-events: none;
}

section {
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0;
  pointer-events: none;

  &:not(.section-big):not(.section-big2) .container {
    max-width: rem(1000px);
  }

  h1,
  h2 {
    color: #fff;
  }
}

::v-deep {
  .words,
  .word,
  .char {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
  }
}

h2 {
  @include breakpoint('sm-and-down') {
    font-size: 1.5rem;
    line-height: 1.2;
  }
}

svg {
  max-width: rem(300px);

  path {
    stroke: #fff;
  }
}

.section-big {
  text-align: left;

  h2 {
    font-size: 13vw;
    line-height: 1em;
  }
}

.section-big2 {
  text-align: left;

  h2 {
    font-size: 10vw;
    line-height: 1em;
  }
}

.section-small {
  .section-image {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 250px;
    transform: translate(-80%, -60%);

    @include breakpoint('lg-and-up') {
      bottom: 30%;
    }

    @include breakpoint('md-and-up') {
      top: auto;
      left: auto;
      bottom: 16%;
      right: 50%;
      transform: none;
      max-width: 350px;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #544242;
      z-index: 2;
      content: '';
      opacity: 0.3;
    }
  }

  .section-content {
    transform: translateY(18%);
    @include breakpoint('md-and-up') {
      margin-left: 40%;
      transform: translateY(12%);
    }

    svg {
      max-width: 160px;
      margin-left: 0;
      margin-right: auto;
      display: block;
      stroke-width: 2;
      margin-top: 10px;
    }
  }

  h2 {
    font-size: rem(16px);
    text-align: left;
    line-height: 1.2;
    @include breakpoint('md-and-up') {
      font-size: rem(30px);
    }
  }
}

.invite-to-scroll {
  position: absolute;
  bottom: rem(10px);
  right: 50%;
  transform: translateX(50%);
  pointer-events: auto;
  border-color: #fff;

  @include breakpoint ('sm-and-down') {
    bottom: rem(30px);
  }

  ::v-deep h5 {
    line-height: 1;
    color: #fff;
  }
}
</style>
